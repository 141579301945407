import { useState } from "react";

import Login from "../login/login";
import Menu from "../../componentes/menu/Menu";


function Inicio({navigation}) {
    //const [isLoggedIn, setIsLoggedIn] = useState(false);

    // if (localStorage.length>0){
    //     const datoSesion = localStorage.getItem('sesion');
    //     if (datoSesion.token===''){
    //         setIsLoggedIn(false);
    //     }
    // }
    

    return (
        <Menu />  
    );

}

export default Inicio;
