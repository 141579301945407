import dataVotosLista from "../../../api/dataVotosLista";
    
const ListaConTotal = (token) => {    
    return new Promise(responde => {
        dataVotosLista(token).then((result) => {
            let listaconTotal = [];   
            let listaLogoTipos = [];
            if (result.data){                
                const tipoPresidente = [result.data[5]];
                const tipoGobernador = [result.data[3]];

                // console.log(tipoPresidente)
                // console.log(tipoGobernador);

                const tipoVot = tipoPresidente.concat(tipoGobernador);

                tipoVot.map(tab => {
                    let acan = tab.candidatos;            
                    let regcands = [];
                    let totalVotos = 0;
                    acan.map(acandidatos => {
                        totalVotos += parseInt(acandidatos.voto);
                        regcands.push({
                             id : acandidatos.id,
                             actaId: acandidatos.actaId,
                             nombreCompleto: acandidatos.nombreCompleto,
                             tipoCandidato: acandidatos.tipoCandidato,
                             nunicipioId: acandidatos.municipioId,
                             partidoId: acandidatos.partidoId,
                             tipoActaId: acandidatos.tipoActaId,
                             nombreMunicipio: acandidatos.nombreMunicipio,
                             nombrePartido: acandidatos.nombrePartido,
                             clavePartido: acandidatos.clavePartido,
                             logoPartido: acandidatos.logo,
                             voto: acandidatos.voto,
                             noesTotal: false,
                             writable: true
                         });            
                         let pos = listaLogoTipos.findIndex((item) => item.clavePartido===acandidatos.clavePartido);
                         if (pos<0){
                            listaLogoTipos.push({
                                clavePartido: acandidatos.clavePartido,
                                logoPartido: acandidatos.logo
                            });
                         }
                    });
                    
                    if (acan.length>0){
                        regcands.push({
                            id : 'totalId',
                            nombreCompleto: 'TOTAL DE VOTOS',
                            tipoCandidato: '',
                            nunicipioId: acan[0].nunicipioId,
                            partidoId: '',
                            tipoActaId: acan[0].tipoActaId,
                            nombreMunicipio: acan[0].nombreMunicipio,
                            nombrePartido: '',
                            clavePartido: '',
                            logoPartido: '',
                            voto: totalVotos,
                            noesTotal: true,
                            writable: true
                        });
            
                        listaconTotal.push({
                            id:tab.id,
                            clave: tab.clave,
                            descripcion: tab.descripcion,
                            candidatos: regcands,
                            v:tab.v,
                            enumerable: tab.enumerable,
                            writable: true
                        });
                    }                   
                    
                });            
                responde([listaconTotal,listaLogoTipos]);
            }else{
                // console.log(Object.keys(result));
                // console.log(result.response.status);
                if (result.response){
                    if (result.response.status){
                        if (result.response.status===401){
                            responde([{'status':401}]);
                        }
                    }
                }
            }
            
            
        });
    });
}
    

export default ListaConTotal;
