import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    id: '',
    userName: '',
    email: '',
    nombreCompleto: '',
    casillaId: '',
    casilla: '',
    ubicacion: '',
    tipoCasilla: '',
    seccion: '',
    partidoId: '',
    partido: '',
    token: '',
    vigenciaToken: '',    
    refresToken: '',
    vigenciaRefreshToken: ''
};

const loginSlice = createSlice({
    name: "plogin",
    initialState,
    reducers: {
        addLoginDatos: (state,action) => {
            const {id,token,refreshToken,userName,email,nombreCompleto,casillaId,casilla,ubicacion,tipoCasilla,seccion,partidoId,partido} = action.payload                       
            state.id = id;
            state.token = token;
            state.refresToken =  refreshToken;
            state.userName = userName;
            state.email = email;
            state.nombreCompleto = nombreCompleto;
            state.casillaId = casillaId;
            state.casilla = casilla;
            state.ubicacion = ubicacion;
            state.tipoCasilla = tipoCasilla;
            state.seccion = seccion;
            state.partidoId = partidoId;
            state.partido = partido;
        },
        iniciaSesion : (state,action) => {
            state.id='';
            state.userName= '';
            state.email= '';
            state.nombreCompleto= '';
            state.casillaId= '';
            state.casilla= '';
            state.ubicacion= '';
            state.tipoCasilla= '';
            state.seccion= '';
            state.partidoId= '';
            state.partido= '';
            state.token= '';
            state.vigenciaToken= '';
            state.refresToken= '';
            state.vigenciaRefreshToken= '';
        }            
    }
});
export const { addLoginDatos, iniciaSesion } = loginSlice.actions;
export default loginSlice.reducer;