import { apiObservaciones } from "../../api/apiObservaciones";

export const ObservaUpdate = (tipoActaPos, listaCompleta, camposObserva) => {

    return new Promise(async (resolve,reject) => {
        let lista_porTipodeVoto = listaCompleta[tipoActaPos];

        // console.log(lista_porTipodeVoto);
    
        let _tipoActaId='';
        let _actaDtos = [];
    
        if (lista_porTipodeVoto){
            if (lista_porTipodeVoto.candidatos){
                const _votos_porTipo = lista_porTipodeVoto.candidatos.filter(i => i.id !== 'totalId');
                _tipoActaId = lista_porTipodeVoto.candidatos[0].tipoActaId;
                _votos_porTipo.map( i => (                    
                    _actaDtos.push({
                        "id":i.id,
                        "tipoActaId": i.tipoActaId,
                        "partidoId":i.partidoId,
                        "candidatoId":i.id,
                        "votos":parseInt(i.voto),
                        "tipoActaPos":tipoActaPos+1,
                        "casillaId": camposObserva.casillaId
                    })                    
                ));
            }
        }    
        
        console.log(_actaDtos);
        console.log(camposObserva);
    
        let dataObservaciones = {};
    
        let _metodo = 'post';
        //if (camposObserva.idObservacion!=="PmN7e126"){ // es 0
        if (camposObserva.idObservacion!==""){ // es 0
            _metodo='put';
            dataObservaciones = {
                "id":camposObserva.idObservacion,
                "observaciones": camposObserva.observaciones,
                "tipoObservacionId": camposObserva.tipoObservacionId,
                "incidenciaId": camposObserva.incidenciaId,                
                "representante": camposObserva.representante,
                "asistio": camposObserva.asistio,
                "tipoActaId": _tipoActaId,
                "actaDtos": _actaDtos,
                "casillaId": camposObserva.casillaId,
                "seccionId": camposObserva.seccionId
            }
        }else{
            dataObservaciones = {                 
                "observaciones": camposObserva.observaciones,
                "tipoObservacionId": camposObserva.tipoObservacionId,
                "incidenciaId": camposObserva.incidenciaId,
                "casillaId": camposObserva.casillaId,
                "representante": camposObserva.representante,
                "asistio": camposObserva.asistio,
                "seccionId": camposObserva.seccionId,
                "tipoActaId": _tipoActaId,
                "actaDtos": _actaDtos
            }
        }
        
        // console.log(camposObserva.idObservacion);
        // console.log(_tipoActaId);
        console.log(dataObservaciones);
        // console.log(_metodo)

        apiObservaciones(_metodo,dataObservaciones)
        .then(respondio => {            
            resolve([respondio,_metodo]);
        })
        .catch(erro => {            
            resolve(erro);
        });

        

    });




}