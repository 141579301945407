import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './login.css';
import { apiLogin } from '../../api/apiLogin';
import { addLoginDatos } from '../../redux/loginSlice';

function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');  
  const [logeando, setLogeando] = useState(false);

  const navigate = useNavigate()
  
  const handleLogin = async (event) => {
            event.preventDefault();                       
            
            setLogeando(true);

            const dataForm = {
              "userName": username,
              "password": password
            }            

            apiLogin(dataForm)
              .then(response => {

                console.log(response.data);
                dispatch(addLoginDatos(response.data));
                localStorage.setItem('sesion',JSON.stringify(response.data));
                setLogeando(false);
                //setIsLoggedIn(true);
                navigate('/inicio');

            })
            .catch(erro => {
               localStorage.clear();
            });
  };
  
  return (    
      <div className="LGcontainer">
        <div className="LGscreen">
          <div className="LGscreen__content">
            {logeando && <Box sx={{ width: '100%' }}><LinearProgress /></Box>}            
            <form className="LGlogin">
              <h3>Prep Login</h3>
              <Divider />
              <div className="LGlogin__field">
                <PersonIcon />
                <input 
                  type="text" 
                  className="LGlogin__input" 
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Ingrese usuario"
                  autoComplete="off" 
                  required
                  />
              </div>
              <div className="LGlogin__field">
                <LockIcon />
                <input 
                  type="password" 
                  className="LGlogin__input"             
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Ingrese contraseña"
                  autoComplete="off" 
                  required
                  />
              </div>
              <button className="LGbutton LGlogin__submit"  onClick={handleLogin}>
                <span className="LGbutton__text">Iniciar Sesión</span>
                <i className="LGbutton__icon fas fa-chevron-right"></i>
              </button>				
            </form>
          </div>
          <div className="LGscreen__background">
            <span className="LGscreen__background__shape LGscreen__background__shape4"></span>
            <span className="LGscreen__background__shape LGscreen__background__shape3"></span>		
            <span className="LGscreen__background__shape LGscreen__background__shape2"></span>
            <span className="LGscreen__background__shape LGscreen__background__shape1"></span>
          </div>  			  
        </div>
      </div>    
  );
}

export default Login;