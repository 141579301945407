import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

export default function DialogAlert({mensaje,titulo,setUploadPercent,alerta,setAlerta}){
  const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  console.log('llego a dialog');
  const handleClose = () => {
    setUploadPercent(0)
    
    let _alerta = {...alerta};
    _alerta.mensaje='';
    _alerta.titulo='';
    setAlerta(_alerta);

    setOpen(false);
  };

  React.useEffect(()=>{
    
    setOpen(true);
  },[])

  return (
        
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {titulo}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {mensaje}
          </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
   
  );

}