
function Data3dChart(index,listaCandidatos) {
    var partidos = [];    
    var xseries = [];
    var Lseries = [];

    const agrupaySuma = (data, field) => {
      const groupedData = data.reduce((accumulator, item) => {
        const key = item[field];
        if (!accumulator[key]) {
          accumulator[key] = { [field]: key, logo: item.logoPartido, sum: 0 };
        }
        accumulator[key].sum += parseInt(item.voto);
        return accumulator;
      }, {});
    
      return Object.values(groupedData);
    };       
     
    const colorPartido = (clavePartido) => {
      var color = '';
      switch(clavePartido){
        case 'PAN':
          color = '#2a9df4'
          break;
        case 'PRI':
          color = '#d5201e'
          break;
        case 'PRD':
          color = '#fbd428'
          break;
        case 'PT':
          color = '#c30010'
          break;
        case 'PVEM':
          color = '#6fd404'
          break;
        case 'MC':
          color = '#e48200'
          break;
        case 'MORENA':
          color = '#621132'
          break;
      }
      return color;
    }
    
    var _listaCandidatos = listaCandidatos.filter(i => i.clavePartido != '');
    var Titulo="";
    if (_listaCandidatos.length>0){
      Titulo = _listaCandidatos[0].descripcion;
    }
    const grupoPartidos = agrupaySuma(_listaCandidatos, 'clavePartido');

    
    grupoPartidos.map(item => (
       xseries.push(
          {          
            name: item.clavePartido,
            data: [{
              name: item.clavePartido,
              y: item.sum,
              color: colorPartido(item.clavePartido),
              logo: item.logo
            }]            
          }                 
       )
    )); 
    
    grupoPartidos.map(l => (
      Lseries.push({
        name: l.clavePartido,
        y: l.sum,
        color: colorPartido(l.clavePartido)
      })
    ));

    grupoPartidos.map(item => (
      partidos.push(item.clavePartido)
    ));    

    const jsonDataChart = {
        chart: {
            style: {
                fontFamily: 'Arial',
                fontSize: 14,
                width:'100%'
            },
            type: 'column',
            options3d: {
              enabled: true,
              alpha: 0,
              beta: 10,
              depth: 115              
            },           
        },
        title: {
            text: 'Gráficas Generales de '+Titulo,
            align: 'left',
            margin: 50,
            x: 50,
            style: {
              fontSize: 18,
              fontWeight: 'bold',
              color: 'rgba(0,0,0,.9)'
            }
        },
        subtitle: {
            text: 'Total de Votos ',          
            align: 'center',
            x: 52,          
            style: {
              fontSize: 16
            }
        },
        xAxis: {
            type: 'category',
            categories: partidos,
            labels: {
              style: {
                useHTML: true,                            
                skew3d: false,
                fontFamily: 'Arial',
                fontSize: '9pt',
                fontWeight: 500
              }
            }
        },
        yAxis: {
            title: {
                useHTML: true,
                text: 'Votos',
                margin:40,
                fontFamily: 'Arial',
                fontSize: '11pt',
            },
            labels: {
              style: {
                  fontFamily: 'Arial',
                  fontSize: '10pt' // Cambiar el tamaño de la fuente de las etiquetas del eje Y
              }
          }
        },
        plotOptions: {
            series: {
                cursor: 'pointer'
            },
            column: {
                grouping: false,
                pointPadding: 0.2,
                borderWidth: 0,                                                                
                dataLabels: {
                    useHTML: true,
                    enabled: true,        
                    fontFamily: 'Arial',        
                    format: '<div style="display: flex;justify-content: center;"><img src="{point.logo}" style="width: 40px; height: 40px;" /></div><br /><span style="display: flex;justify-content: center;">{point.y:,.0f}</span>',
                    style: {
                        color: 'black',
                        fontFamily: 'Arial',                        
                        fontSize: '10pt'
                    } 
                }
            },
            arearange: {
                lineWidth: 1
            },     
            areaspline: {
                lineWidth: 1,
                marker: {
                  enabled: false,
                  symbol: 'circle',
                  radius: 3
                }
              },
              areasplinerange: {
                lineWidth: 1
              },
              boxplot: {
                groupPadding: 0.15,
                pointPadding: 0.25,
                fillColor: 'rgba(177,69,0,1)',
                color: 'rgba(177,69,0,1)',
                lineWidth: 0,
                stemWidth: 1,
                whiskerWidth: 0,
                whiskerLength: 25,
                medianWidth: 2,
                medianColor: 'rgba(255,255,255,1)',
                //pointWidth:4
              },
              bubble: {
                minSize: '0.25%',
                maxSize: '17%'
              }
            
        },
        tooltip: {          
            enabled: false
        },  
        legend: {       
            enabled: false,     
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',            
            y: 63,
            itemMarginTop: 0,
            itemMarginBottom: 0,
            padding:4,
            floating: true,
            itemStyle: {     
              font: '9pt Arial',         
              color: null,
              fontWeight: 700
            },
            itemHoverStyle: {
                color: '#828282'
            },
            itemHiddenStyle: {
                color: '#cccccc'
            }
        },  
        credits: {
            enabled: false 
        },

        series:xseries,
        
        responsive: {  
            rules: [{  
              condition: {  
                maxWidth: 800  
              },  
              chartOptions: {  
                legend: {  
                  enabled: false,
                },
                title: {            
                  style: {
                    fontSize: 16
                  }
                },             
                subtitle: {                  
                    style: {
                      fontSize:13
                    }
                },
                xAxis:{                                     
                    labels: {
                      useHTML: true, 
                      padding: 9,                      
                      rotation: 90,
                      style: {                        
                          fontSize: '9pt',
                      }
                    } 
                },
                yAxis: {
                  title: {
                    useHTML: true,
                    text: 'Votos',
                    margin: 5,
                    style: {
                      color: 'black',
                      fontSize: '10pt'
                    },
                  }          
                },
                plotOptions: {                
                  column: {                  
                    dataLabels: {                                  
                      style: {
                        color: 'black',
                        fontSize: '10pt'
                      },
                    }
                  }
                }             
                
              } 
            }]
          }

    }

    const jsonLineChart = {
      chart: {
          style: {
              fontFamily: 'Arial',
              fontSize: 9,
              'float': 'left'
          },
          type: 'line'          
      },
      title: {
          text: 'Gráficas Generales de '+Titulo,
          align: 'left',
          margin: 50,
          x: 50,
          style: {
            fontSize: 18,
            fontWeight: 'bold',
            color: 'rgba(0,0,0,.9)'
          }
      },
      subtitle: {
          text: 'Total de Votos',
          align: 'center',
          x: 52,          
          style: {
            fontSize:16
          }
      },
      xAxis: {
          type: 'category',
          categories: partidos,
          labels: {
            style: {
              useHTML: true,                            
              skew3d: false,
              fontFamily: 'Arial',
              fontSize: '9pt',
              fontWeight: 500
            }
          }
      },
      yAxis: {
          title: {
              useHTML: true,
              text: 'Votos',
              margin:40,
              fontFamily: 'Arial',
              fontSize: '12pt',
              fontWeight: 500
          },
          labels: {
              style: {
                  fontSize: '10pt' // Cambiar el tamaño de la fuente de las etiquetas del eje Y
              }
          }
      },
      plotOptions: {
          series: {
              cursor: 'pointer'
          },
          column: {
              grouping: false,
              pointPadding: 0.2,
              borderWidth: 0,                                                                
              dataLabels: {
                  useHTML: true,
                  enabled: true,                
                  format: '<span style="display: flex;justify-content: center;">{point.y:,.0f}</span>',
                  style: {
                      color: 'black',
                      fontSize: '10pt'
                  } 
              }
          },
          arearange: {
              lineWidth: 1
          },     
          areaspline: {
              lineWidth: 1,
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 3
              }
            },
            areasplinerange: {
              lineWidth: 1
            },
            boxplot: {
              groupPadding: 0.15,
              pointPadding: 0.25,
              fillColor: 'rgba(177,69,0,1)',
              color: 'rgba(177,69,0,1)',
              lineWidth: 0,
              stemWidth: 1,
              whiskerWidth: 0,
              whiskerLength: 25,
              medianWidth: 2,
              medianColor: 'rgba(255,255,255,1)',
              //pointWidth:4
            },
            bubble: {
              minSize: '0.25%',
              maxSize: '17%'
            }
          
      },
      tooltip: {          
          enabled: false
      },  
      legend: {       
          enabled: false,     
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'top',            
          y: 63,
          itemMarginTop: 0,
          itemMarginBottom: 0,
          padding:4,
          floating: true,
          itemStyle: {     
            font: '10pt Arial',         
            color: null,
            fontWeight: 700
          },
          itemHoverStyle: {
              color: '#828282'
          },
          itemHiddenStyle: {
              color: '#cccccc'
          }
      },  
      credits: {
          enabled: false 
      },

      series:[{
        name: '',
        visible: true,
        lineColor: '#000000',
        lineWidth: 1,
        data: Lseries
      }],
      
      responsive: {  
          rules: [{  
            condition: {  
              maxWidth: 800  
            },  
            chartOptions: {  
              legend: {  
                enabled: false,
              },
              title: {            
                style: {
                  fontSize: 16
                }
              },             
              subtitle: {                  
                  style: {
                    fontSize:13
                  }
              },
              xAxis:{                                     
                  labels: {
                    useHTML: true, 
                    padding: 9,                      
                    rotation: 90,
                    style: {                        
                        fontSize: '11pt',
                    }
                  } 
              },
              yAxis: {
                title: {
                  useHTML: true,
                  text: 'Votos',
                  margin: 5,
                  style: {
                    color: 'black',
                    fontSize: '11pt'
                  },
                }          
              },
              plotOptions: {                
                column: {                  
                  dataLabels: {                                  
                    style: {
                      color: 'black',
                      fontSize: '10pt'
                    },
                  }
                }
              }             
              
            } 
          }]
        }

  }

    return [jsonDataChart,jsonLineChart];

}

export default Data3dChart;