import axios from "axios";
import config from "../Config";

const ApiManager = axios.create({
    //baseURL: 'https://localhost:7134/api',
    baseURL: config.api, // 'http://sono.sytes.net/apiprep24/api',
    responseType: 'json',
    withCredentials:false,
});

export default ApiManager;