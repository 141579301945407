import React from 'react';
import { useEffect, useState } from "react";
import Menu from "../../componentes/menu/Menu";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CboSeccionesIndica from "./CboSeccionesIndica";
import "./index.css"




export default function Indicadores(){
    const [vTipoActa,setVTipoActa] = useState(null);
    const [loading, setLoading] = useState(false);
    const [vSeccion,setVSeccion] = useState(null);
    const [vCaptura,setVCaptura] = useState(null);
    const [casillasData,setCasillasData] = useState(null);
    const [casillasSort, setCasillasSort] = useState([]);
    
    const handleCapturadas = (event, newValue) => {
        setVCaptura(newValue);
        alert(vSeccion.value+' -> '+vSeccion.label+' cap: '+newValue.value);

    }

    //  "Database": "Server=89.116.51.160;Port=5437;Database=siel_morelos;User Id=sielmorelos; Password=qQJSP9fQ2Jwj1D14AdnhUULR;"
    //  "Database": "Server=89.116.51.160;Port=5437;Database=siel_morelos;User Id=sielmorelos; Password=qQJSP9fQ2Jwj1D14AdnhUULR;"

    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

      useEffect(() => {
        if (casillasData){
            if (casillasData.length>0){
    
                setLoading(true);
    
                const sortedArray = [...casillasData].sort((a, b) => {
                    // Ordenar por seccion
                    const aVSeccion = parseInt(a.seccion);
                    const bVSeccion = parseInt(b.seccion);

                    if (aVSeccion < bVSeccion) {
                      return -1;
                    }
                    if (aVSeccion > bVSeccion) {
                      return 1;
                    }                   
                    

                    // Si seccion es igual, ordenar por casilla->clave
                    if (a.clave < b.clave) {
                      return -1;
                    }
                    if (a.clave > b.clave) {
                      return 1;
                    }
                  
                    // Si seccion y casillaId son iguales, mantener el orden original
                    return 0;
                  });

                 

                setCasillasSort(sortedArray);
                setLoading(false);
            }else{
              setCasillasSort([]);
            }
        }

      },[casillasData])


    

    const casillasCard = casillasSort.map((item,index) => (
        <div className={item.capturo===0 ? "cardNo" : "card"}>
            <h5 className={item.capturo===0 ? "hCardNo" : "hCardSi"} >{item.capturo===0 ? "NO CAPTURADA" : "SI CAPTURADA" }</h5>
            <hr />
            <span className="seccionIndica">Sección: {item.seccion}</span>
            <span className="casillaIndica">Casilla: {item.clave}</span>            
            {item.prioritaria==="S" && 
              <span className="prioIndica">PRIORITARIA</span>
            }
            
            <span className="descrIndica">{item.descripcion.length > 85
                                    ? `${item.descripcion.slice(0, 85)}...`
                                    : item.descripcion}</span>            
        </div>
    ));


    return(        
        <div className="containerIndica">
            <Menu />
            
            <div className="containFiltros">                
                <CboSeccionesIndica dataCasillas={setCasillasData} consultando={setLoading} />                
            </div>
            {loading && <Box sx={{ width: '100%' }}><LinearProgress /></Box>}       
            <React.Fragment key={generateRandomString(10)}>
              <div className="containerCard">
                  {casillasCard}
              </div>
            </React.Fragment>
        </div>


    );

}