import axios from "axios";
import Config from "../Config";


export function apiObservaPDF(idObservacion,formDato,setUploadPercent){
    return new Promise( async (resolve,reject) => {
        const API_URL = Config.api+'/actas/ObservacionesActa';                
        // metodo = 'post' ó 'put' 

        let sesion = localStorage.getItem('sesion');
        let sesionJS = JSON.parse(sesion);      
        let _token = sesionJS.token;          

        const config = {
            onUploadProgress: (ProgressEvent) => {
                const {loaded,total} = ProgressEvent;
                const percent = Math.floor((loaded*100)/total);
                console.log('percent: ', percent);

                if (percent<100){                   
                    setUploadPercent(percent);
                }
            },
            headers: {
               Authorization: `Bearer ${_token}`,
              'Content-Type': 'multipart/form-data',
            }
          };        

          console.log('idObservacion : ',idObservacion);

        if (idObservacion!==''){
            await axios.put(API_URL,formDato,config)
            .then(response => {
                resolve(response);                        
            })
            .catch(erro => {
                console.log(erro);
                reject(erro);
            });
        }else{
            await axios.post(API_URL,formDato,config)
            .then(response => {
                resolve(response);                        
            })
            .catch(erro => {
                console.log(erro);
                reject(erro);
            });
        }
        
    } );
}