import { useState } from "react";
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Login from "./vistas/login/login";
import Inicio from "./vistas/Inicio/inicio";
import Votos from './vistas/tipoActas/tactas';
import Dash from "./vistas/Dash/Dash";
import Indicadores from "./vistas/Indicadores";

function App() {  

  return ( 

    <div className="App">      

        {/* <Inicio /> */}
        
        <Routes>                          
            <Route path="/" element={<Login /> } />     
            <Route path="/inicio" element={<Inicio /> } />        
            <Route path="/votos" element={<Votos /> } />
            <Route path="/dash" element={<Dash /> } />  
            <Route path="/indicadores" element={<Indicadores /> } /> 
        </Routes>

    </div>
    
  );
}

export default App;
