import axios from "axios";
import Config from "../Config";

export function apiGet(ruta) {
    return new Promise( async (resolve,reject) => {

        const sesion = JSON.parse(localStorage.getItem('sesion'));
        const token = sesion.token;

        const endpoint = Config.api+ruta;

        const opciones = {
            headers: {
                "Authorization" : `Bearer ${token}`
            }
        }

        await axios.get(endpoint, opciones )
        .then(res => {            
            resolve(res);
        })
        .catch((erro) => {
           reject(erro);
        });

    });

}