import { useEffect, useState } from "react";
import SDown from "../../../componentes/SDown/SDown";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { apiGet } from "../../../api/apiGet";




export default function CboSecciones(props){
    const {seccion,setSeccion,casilla,setCasilla,handleCasillaV, setListaTotal} = props;    
    const [dataSe,setDataSe] = useState([]);
    const [dataSec,setDataSec] = useState([]);
    const [dataCasilla,setDataCasilla] = useState([]);

    useEffect(() => {
        const obtieneSecciones = async () => {
            const endApi = "/secciones/SeccionesConCasillasAsignadas";
            const dataSecciones = await apiGet(endApi);
            
            setDataSe(dataSecciones.data);

            const aSecc = dataSecciones.data;

            const datoSecc = aSecc.reduce((acc,curr) => {
                return [...acc, {value: curr.id, label: curr.seccion}];
            },[]);

            // console.log(datoSecc);

            setDataSec(datoSecc);
        }
        
        
        obtieneSecciones();
    },[]);

    const handleSeccion = (event, newValue) => {
        console.log(newValue);     
        setListaTotal([]);
        setCasilla([]);   
        if (newValue){
            const seccion = dataSe.filter(i=>i.id===newValue.value);        
            console.log(seccion);
            const casillas = seccion[0].casillas;
            console.log(casillas);
            setSeccion(newValue);
    
             const datoCasilla = casillas.reduce((acc,curr) => {
                 return [...acc, {value: curr.id, label: curr.clave}];
             },[]);
    
             setDataCasilla(datoCasilla);
    
             console.log(datoCasilla);
        }        
    }   
    

    return(
        // <SDown setCombo={setSeccion} datos={dataSec} label={"Sección"} valor={valor} />
        <div style={{ display:"flex", flexDirection:"row" }}>
            <Autocomplete
                disablePortal                
                id="combo-box-seccion"
                value={seccion}
                options={dataSec}
                onChange={handleSeccion}
                sx={{ width: 120, borderColor:"#CCC", marginRight:4 }}
                renderInput={(params) => <TextField {...params} label="Sección" />}
            />

            <Autocomplete
                disablePortal
                id="combo-box-casilla"
                value={casilla}
                options={dataCasilla}
                onChange={handleCasillaV}
                sx={{ width: 215, borderColor:"#CCC" }}
                renderInput={(params) => <TextField {...params} label="Casilla" />}
            />
        </div>
    )


}