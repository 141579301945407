import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    FechaActualizacion:null
};

const ufecha = createSlice({
    name: "pufecha",
    initialState,
    reducers: {
        addFecha: (state,action) => {
            const {FechaActualizacion} = action.payload                       
            state.FechaActualizacion = FechaActualizacion            
        }
    }
});
export const { addFecha } = ufecha.actions;
export default ufecha.reducer;

