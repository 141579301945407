import { configureStore } from '@reduxjs/toolkit';
import votoReducer from './votoSlice';
import fechaReducer from './ufecha';
import loginSlice from './loginSlice';
import dataCandidatos from './dataCandidatos';
import logoTipoSlice from './logoTipoSlice';

const reducer = {
  votos: votoReducer,
  ufecha: fechaReducer,  
  dlogin : loginSlice,
  dCandidatos: dataCandidatos,
  dLogotipo:logoTipoSlice
};

const store = configureStore({
  reducer: reducer,
});

export default store;