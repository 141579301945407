import ApiManager from "./../constants/apiManager";

export const dataVotosTotal = async _data => {    
    
    try{        
        
        // console.log(_data);

        let ruta = '/actas/votos';        
        if (_data.TipoActaPos!=null){
            ruta = '/actas/votos?TipoActaId='+_data.TipoActaPos;
        }       
        
        let _sesion = localStorage.getItem('sesion');
        let sesion = JSON.parse(_sesion);
        let token = sesion.token;   

        const respondio = await ApiManager(ruta,{
            method:"GET",
            headers:{
                Authorization: `Bearer ${token}`,
                'Content-Type':"application/json"
            }
            //,data: _data
        });
        //console.log(respondio);
        return respondio;
    }catch (error){
        return error.response.data;
    }
}

export default dataVotosTotal;