import React,{ useEffect } from 'react';
import {  useSelector,useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
// import LineAxisIcon from '@mui/icons-material/LineAxis';
import PieChartIcon from '@mui/icons-material/PieChart';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ListaConTotal from '../tabs/dsVotos/ListaConTotal';
import { useNavigate } from 'react-router-dom'
import { iniciaSesion } from '../../redux/loginSlice';
import { addDataCandidato } from '../../redux/dataCandidatos';
import { addDataLogo } from '../../redux/logoTipoSlice';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Menu({navigation}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [titulo, setTitulo] = React.useState('');  

  let sesion = localStorage.getItem('sesion');
  let sesionJS = JSON.parse(sesion);      
  let token = sesionJS.token;
  
  const navigate = useNavigate();  

   const dispatch = useDispatch(); 
   const dataLogin = useSelector((state) => state.dlogin);   

   const tieneStatus = (obj) => {        
    return obj && obj.status !== null && obj.status !== undefined && obj.status !== "";          
  }

  useEffect(() => {      
      ListaConTotal(token).then(listaTotal => {
        const vencio = tieneStatus(listaTotal[0]);
            if (vencio){
                console.log('va a login por vencmiento de token');
                localStorage.clear()
                navigate('/');           
                return;
            }
          // console.log(listaTotal);
          if (listaTotal){
            dispatch(addDataCandidato(listaTotal[0].filter(i=>i.id!=="")));
            dispatch(addDataLogo(listaTotal[1]));
          }          
      });      
  },[]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed"  open={open} className='appBarMenu' >
        <Toolbar sx={{ width: 1 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 0, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}>
            <Typography variant="h6" noWrap component="div" sx={{ mr:0 }} >
                {titulo}            
            </Typography>
            <Typography variant="subtitle3" noWrap component="div" >
                <span className='dataLogin'>{sesionJS.nombreCompleto}</span>
            </Typography>
          </Box>  
        </Toolbar>
      </AppBar>
      <Drawer
       
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: 'var(--menu-bg-color2)' 
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}        
      >
        <DrawerHeader  className='appListaMenu'>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List  className='appListaMenu'>
            <ListItem key={'Votos'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                   <HowToVoteIcon />
                </ListItemIcon>
                <ListItemText >
                    <Link to="/votos" onClick={() => setTitulo('Votos')} >Votos</Link>
                </ListItemText>
              </ListItemButton>
            </ListItem> 
            <ListItem key={'Dash'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                   <PieChartIcon />
                </ListItemIcon>
                <ListItemText>
                    <Link to="/dash" onClick={() => setTitulo('Dash')} >Dash</Link>
                </ListItemText>
              </ListItemButton>
            </ListItem>   
            <ListItem key={'Indicadores'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                   <AccountTreeIcon />
                </ListItemIcon>
                <ListItemText>
                    <Link to="/indicadores" onClick={() => setTitulo('Indicadores')} >Indicadores</Link>
                </ListItemText>
              </ListItemButton>
            </ListItem> 
            <ListItem key={'Salir'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                   <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText>
                    <Link to="/" onClick={() => localStorage.clear() } >Salir</Link>
                </ListItemText>
              </ListItemButton>
            </ListItem>           
        </List>
        <Divider />
        {/* <List className='appListaMenu'>          
            <ListItem key={'Salir'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText>
                    <Link to="/" onClick={() => handleLogout()} >Salir</Link>
                </ListItemText>
              </ListItemButton>
            </ListItem>          
        </List>  */}
      </Drawer>     
    </Box>
  );
}