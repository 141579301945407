

export const LogosData = [
    [
        {
            "clavePartido": "PAN",
            "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pan-1.png"
        },
        {
            "clavePartido": "PRI",
            "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pri-2.png"
        },
        {
            "clavePartido": "PRD",
            "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/prd.png"
        },
        {
            "clavePartido": "PT",
            "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pt.png"
        },
        {
            "clavePartido": "PVEM",
            "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pvem-2.png"
        },
        {
            "clavePartido": "MC",
            "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/movimiento-ciudadano.png"
        },
        {
            "clavePartido": "MORENA",
            "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/morena.png"
        },
        {
            "clavePartido": "NR",
            "logoPartido": "https://e7.pngegg.com/pngimages/75/458/png-clipart-logo-person-university-of-california-santa-barbara-windows-ten-rectangle-logo.png"
        },
        {
            "clavePartido": "NULO",
            "logoPartido": "https://w7.pngwing.com/pngs/756/477/png-transparent-circle-close-cross-incorrect-invalid-x-delete-flat-actions-icon-thumbnail.png"
        }
    ]
]