import axios from "axios";
import Config from "../Config";


export function apiLogin(formDato){
    return new Promise( async (resolve,reject) => {
        const API_URL = Config.api+'/Authorization/Authenticate';        
        
        const config = {
            method: 'post',
            url: API_URL,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify(formDato),
          };

        await axios( config )
        .then(response => {
            resolve(response);                        
        })
        .catch(erro => {
            console.log(erro);
            reject('Error...');
        });
    } );
}