import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const logoTipoSlice = createSlice({
    name: "pdatalogotipo",
    initialState,
    reducers: {
        addDataLogo:  (state,action) => {
            Object.assign(state, action.payload);
        }

    }
});

export const { addDataLogo } = logoTipoSlice.actions;
export default logoTipoSlice.reducer;