import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Autocomplete from '@mui/material/Autocomplete';
import { apiGet } from "../../../api/apiGet";
import "./index.css";


export default function CboSeccionesIndica(props){
    const {dataCasillas,consultando} = props;       
    const [seccion,setSeccion] = useState([]);
    const [tipoActa_,setTipoActa_] = useState([]);
    const [captura,setCaptura] = useState([]);
    const [casilla,setCasilla] = useState([]);
    const [priori,setPriori] = useState([]);
    const [entries,setEntries] = useState(null);
    const [dataTipo,setDataTipo] = useState([
        {
            value: "2jNbjNne",
            label:'PRESIDENTE'
        },
        {
            value: "LPvlpvnk",
            label:'GOBERNADOR'
        }
    ]);
    const [dataSec,setDataSec] = useState([]); 
    const [dataCaptura,setDataCaptura] = useState([
        {
            value : '-1',
            label : '- TODAS -'
        },
        {
            value : '0',
            label : 'SIN CAPTURAR'
        },
        {
            value : '1',
            label : 'CAPTURADAS'
        }
    ]);
    const [dataPriori,setDataPriori] = useState([
        {
            value : '-1',
            label : '- TODAS -'
        },
        {
            value : 'S',
            label : 'SI'
        },
        {
            value : 'N',
            label : 'NO'
        }
    ]);

    useEffect(() => {
        const obtieneSecciones = async () => {
            const endApi = "/secciones";
            const dataSecciones = await apiGet(endApi);

            const aSecc = dataSecciones.data;

            const datoSecc = aSecc.reduce((acc,curr) => {
                return [...acc, {value: curr.id, label: curr.seccion}];
            },[]);            

            setDataSec([{ value:'-1',label:'- TODOS -' }, ...datoSecc]);


        }
        
        
        obtieneSecciones();
    },[]);

    const handleTipoActa = (event,newVal) => {
        setTipoActa_(newVal);
        setSeccion({ value:'-1',label:'- TODOS -' });
        setPriori({ value:'-1',label:'- TODOS -' });
        setCaptura({ value:'-1',label:'- TODOS -' });
    }

    const handleSeccion = (event, newValue) => {               
        setSeccion(newValue);                

    }   

    const handlePriori = (event,newValue) => {
        setPriori(newValue);
    }

    const handleCapturadas = async (event, newValue) => {
        setCaptura(newValue);
        setCasilla(newValue);
    }
 
    const handleFindCasillas = async () => {

        dataCasillas([]); 

        const pTipoActa = tipoActa_.value;
        const pSeccion = seccion.value;
        const pCasilla = casilla.value;
        const pPriori = priori.value;
        
        if  (pTipoActa===undefined || pSeccion===undefined || pPriori===undefined || pCasilla===undefined){
            return;
        } 
        
        

        consultando(true);        

        let xSeccion = '';
        if (pSeccion!=='-1'){
            xSeccion = '?seccionId='+pSeccion;
        }
        // ?SeccionId=2jNbjNne&TipoActaId=2jNbjNne'

        const ruta = '/secciones/SeccionesCapturadas/'+pTipoActa+xSeccion;

        const casillaStatusFiltro = await apiGet(ruta);             

       
        let registros = casillaStatusFiltro.data.map((item) => ({
            ...item,
            prioritaria: item.prioritaria ? "S" : "N",
        }));        

        if (pPriori !== '-1'){

            // console.log(pPriori);
            const conprioridad = registros.filter(casilla => casilla.prioritaria === pPriori);
            registros = conprioridad;
            
        }
        
        // console.log(registros);

        if (captura.value!=='-1'){ // capturadas o no capturadas
            const capturadas = parseInt(captura.value);            
            const casillasconStatus = registros.filter(i => i.capturo===capturadas);                       
            dataCasillas(casillasconStatus);            
        }else{
            dataCasillas(registros);
        }

        consultando(false);
        
    }


    return(        
        <>
            <Autocomplete
                disablePortal                                
                value={tipoActa_}
                options={dataTipo}
                onChange={handleTipoActa}
                sx={{ width: 190, borderColor:"#CCC", marginRight:4, '& .MuiOutlinedInput-root': {fontSize:'10pt'} }}
                renderInput={(params) => <TextField {...params} label="Tipo Acta" />}  
                        
            />
            <Autocomplete
                disablePortal                                
                value={seccion}
                options={dataSec}
                onChange={handleSeccion}
                sx={{ width: 190, borderColor:"#CCC", marginRight:4, '& .MuiOutlinedInput-root': {fontSize:'10pt'}  }}
                renderInput={(params) => <TextField {...params} label="Secciónes" />}
            />       

            <Autocomplete
                disablePortal                                
                value={priori}
                options={dataPriori}
                onChange={handlePriori}
                sx={{ width: 190, borderColor:"#CCC", marginRight:4, '& .MuiOutlinedInput-root': {fontSize:'10pt'}  }}
                renderInput={(params) => <TextField {...params} label="Prioritarias" />}
            />       

            <Autocomplete
                disablePortal                                
                value={captura}
                options={dataCaptura}
                onChange={handleCapturadas}
                sx={{ width: 190, borderColor:"#CCC", marginRight:4, '& .MuiOutlinedInput-root': {fontSize:'10pt'}  }}
                renderInput={(params) => <TextField {...params} label="Status" />}
            />                  

                <IconButton aria-label="BarChart" className="iconContainer" onClick={handleFindCasillas} >
                   <FindInPageIcon className='iconFindCasillas'  />
                </IconButton>
           
        </>
    )


}