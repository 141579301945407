

export const TabsData = [    
        {
            "id": "BzN9QNMq",
            "clave": "SEN",
            "descripcion": "Senadores",
            "candidatos": [],
            "observaciones": {
                "asistio": false,
                "casillaId": "",
                "file": "",
                "id": "",
                "incidenciaId": "",
                "observaciones": "",
                "representante": "",
                "tipoActaId": "",
                "tipoObservacionId": ""
            },
            "writable": true
        },
        {
            "id": "YwveKGyD",
            "clave": "DF",
            "descripcion": "Diputados Federales",
            "candidatos": [],
            "observaciones": {
                "asistio": false,
                "casillaId": "",
                "file": "",
                "id": "",
                "incidenciaId": "",
                "observaciones": "",
                "representante": "",
                "tipoActaId": "",
                "tipoObservacionId": ""
            },
            "writable": true
        },
        {
            "id": "DxNJwv8Q",
            "clave": "DL",
            "descripcion": "Diputados Local",
            "candidatos": [],
            "observaciones": {
                "asistio": false,
                "casillaId": "",
                "file": "",
                "id": "",
                "incidenciaId": "",
                "observaciones": "",
                "representante": "",
                "tipoActaId": "",
                "tipoObservacionId": ""
            },
            "writable": true
        },
        {
            "id": "gYNyrv2A",
            "clave": "AYU",
            "descripcion": "Ayuntamientos",
            "candidatos": [],
            "observaciones": {
                "asistio": false,
                "casillaId": "",
                "file": "",
                "id": "",
                "incidenciaId": "",
                "observaciones": "",
                "representante": "",
                "tipoActaId": "",
                "tipoObservacionId": ""
            },
            "writable": true
        },
        {
            "id": "2jNbjNne",
            "clave": "PEUM",
            "descripcion": "Presidente de los Estados Unidos Mexicanos",
            "candidatos": [
                {
                    "id": "2jNbjNne",
                    "nombreCompleto": "Bertha Xóchitl Gálvez Ruiz",
                    "tipoCandidato": 1,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "2jNbjNne",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Partido Acción Nacional",
                    "clavePartido": "PAN",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pan-1.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "2jNbjNne",
                    "nombreCompleto": "Bertha Xóchitl Gálvez Ruiz",
                    "tipoCandidato": 1,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "BzN9QNMq",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Partido Revolucionario Institucional",
                    "clavePartido": "PRI",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pri-2.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "2jNbjNne",
                    "nombreCompleto": "Bertha Xóchitl Gálvez Ruiz",
                    "tipoCandidato": 1,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "YwveKGyD",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Partido de la Revolución Democrática",
                    "clavePartido": "PRD",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/prd.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "BzN9QNMq",
                    "nombreCompleto": "Claudia Sheinbaum Pardo",
                    "tipoCandidato": 1,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "DxNJwv8Q",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "\t\rPartido del Trabajo",
                    "clavePartido": "PT",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pt.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "BzN9QNMq",
                    "nombreCompleto": "Claudia Sheinbaum Pardo",
                    "tipoCandidato": 1,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "LPvlpvnk",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "\t\rPartido Verde Ecologista de México",
                    "clavePartido": "PVEM",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pvem-2.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "YwveKGyD",
                    "nombreCompleto": "Jorge Álvarez Máynez",
                    "tipoCandidato": 1,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "gYNyrv2A",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Movimiento Ciudadano",
                    "clavePartido": "MC",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/movimiento-ciudadano.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "BzN9QNMq",
                    "nombreCompleto": "Claudia Sheinbaum Pardo",
                    "tipoCandidato": 1,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "q7NXR1Ln",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Morena",
                    "clavePartido": "MORENA",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/morena.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "q7NXR1Ln",
                    "nombreCompleto": "No registrado",
                    "tipoCandidato": 2,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "9o1WyNyA",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "No Registrado",
                    "clavePartido": "NR",
                    "logoPartido": "https://e7.pngegg.com/pngimages/75/458/png-clipart-logo-person-university-of-california-santa-barbara-windows-ten-rectangle-logo.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "8KvdZG0D",
                    "nombreCompleto": "Nulo",
                    "tipoCandidato": 1,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "qlGwb1OJ",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Nulo",
                    "clavePartido": "NULO",
                    "logoPartido": "https://w7.pngwing.com/pngs/756/477/png-transparent-circle-close-cross-incorrect-invalid-x-delete-flat-actions-icon-thumbnail.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "totalId",
                    "nombreCompleto": "TOTAL DE VOTOS",
                    "tipoCandidato": "",
                    "partidoId": "",
                    "tipoActaId": "2jNbjNne",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "",
                    "clavePartido": "",
                    "logoPartido": "",
                    "voto": 0,
                    "noesTotal": true,
                    "writable": true
                }
            ],
            "observaciones": {
                "asistio": false,
                "casillaId": "",
                "file": "",
                "id": "",
                "incidenciaId": "",
                "observaciones": "",
                "representante": "",
                "tipoActaId": "",
                "tipoObservacionId": ""
            },
            "writable": true
        },
        {
            "id": "LPvlpvnk",
            "clave": "GOB",
            "descripcion": "Gobernador o Jefe de Gobierno",
            "candidatos": [
                {
                    "id": "LPvlpvnk",
                    "nombreCompleto": "Lucia Virginia Meza Guzman",
                    "tipoCandidato": 2,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "2jNbjNne",
                    "tipoActaId": "LPvlpvnk",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Partido Acción Nacional",
                    "clavePartido": "PAN",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/pan-1.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "DxNJwv8Q",
                    "nombreCompleto": "Margarita Gonzalez Saravia Calderon",
                    "tipoCandidato": 2,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "q7NXR1Ln",
                    "tipoActaId": "LPvlpvnk",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Morena",
                    "clavePartido": "MORENA",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/morena.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "gYNyrv2A",
                    "nombreCompleto": "Jessica Maria Guadalupe Ortega de la Cruz",
                    "tipoCandidato": 2,
                    "nunicipioId": "q7NXR1Ln",
                    "partidoId": "gYNyrv2A",
                    "tipoActaId": "LPvlpvnk",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "Movimiento Ciudadano",
                    "clavePartido": "MC",
                    "logoPartido": "https://www.ine.mx/wp-content/uploads/2018/01/movimiento-ciudadano.png",
                    "voto": 0,
                    "noesTotal": false,
                    "writable": true
                },
                {
                    "id": "totalId",
                    "nombreCompleto": "TOTAL DE VOTOS",
                    "tipoCandidato": "",
                    "partidoId": "",
                    "tipoActaId": "LPvlpvnk",
                    "nombreMunicipio": "Cuernavaca\r",
                    "nombrePartido": "",
                    "clavePartido": "",
                    "logoPartido": "",
                    "voto": 0,
                    "noesTotal": true,
                    "writable": true
                }
            ],
            "observaciones": {
                "asistio": false,
                "casillaId": "",
                "file": "",
                "id": "",
                "incidenciaId": "",
                "observaciones": "",
                "representante": "",
                "tipoActaId": "",
                "tipoObservacionId": ""
            },
            "writable": true
        }
    ]

