

const votGet = (objeto,partidoId,candidatoId) =>{
    let voto = 0;
    if (objeto.length>0){
        const _actas = objeto[0];
        if (_actas){
            const actas = _actas.actas;
            if (actas){
                const candidato = actas.filter(i=>i.candidatoId===candidatoId && i.partidoId===partidoId);
                voto = candidato.length>0 ? candidato[0].votos : 0;
            }            
        }        
    }
    return voto;
}

const ObservaGet = (objeto) =>{
    let regresa = {
        asistio:false,
        casillaId:"",
        file:"",
        id:"",
        incidenciaId:"",
        observaciones:"",
        representante:"",
        tipoActaId:"",
        tipoObservacionId:""
    };

    if (objeto.length>0){
        const _actas = objeto[0];
        if (_actas){
            regresa = {
                asistio:_actas.asistio,
                casillaId:_actas.casillaId,
                file:_actas.file,
                id:_actas.id,
                incidenciaId:_actas.incidenciaId,
                observaciones:_actas.observaciones,
                representante:_actas.representante,
                tipoActaId:_actas.tipoActaId,
                tipoObservacionId:_actas.tipoObservacionId
            }
            
        }        
    }
    return regresa;
}

const ListaConTotalxCasilla = (data) => {    
    return new Promise(responde => {

            let listaconTotal = [];   
            let listaLogoTipos = [];          

            if (data){                
                const tipoVot = data;

                // console.log(tipoVot);

                tipoVot.map(tab => {                    
                        let acan = tab.candidatos  // tab.candidatos;      

                        // console.log(tab);
                        
                        let regcands = [];
                        let totalVotos = 0;                       
                        let observacion = ObservaGet(tab.actaObservacion);
                        acan.map(acandidatos => {
                            const __voto = votGet(tab.actaObservacion,acandidatos.partidoId,acandidatos.id);
                            if (acandidatos.clavePartido!=='NULO'){
                                totalVotos += parseInt(__voto);
                            }
                            
                            regcands.push({
                                id : acandidatos.id,
                                actaId: acandidatos.actaId,
                                nombreCompleto: acandidatos.nombreCompleto,
                                tipoCandidato: acandidatos.tipoCandidato,
                                nunicipioId: acandidatos.municipioId,
                                partidoId: acandidatos.partidoId,
                                tipoActaId: acandidatos.tipoActaId,
                                nombreMunicipio: acandidatos.nombreMunicipio,
                                nombrePartido: acandidatos.nombrePartido,
                                clavePartido: acandidatos.clavePartido,
                                logoPartido: acandidatos.logo,
                                coalicion: acandidatos.coalicion,
                                voto: __voto,
                                noesTotal: false,
                                writable: true
                            });            
                            let pos = listaLogoTipos.findIndex((item) => item.clavePartido===acandidatos.clavePartido);
                            if (pos<0){
                                listaLogoTipos.push({
                                    clavePartido: acandidatos.clavePartido,
                                    logoPartido: acandidatos.logo
                                });
                            }
                        });
                        
                        if (acan.length>0){
                            regcands.push({
                                id : 'totalId',
                                nombreCompleto: 'TOTAL DE VOTOS',
                                tipoCandidato: '',
                                nunicipioId: acan[0].nunicipioId,
                                partidoId: '',
                                tipoActaId: acan[0].tipoActaId,
                                nombreMunicipio: acan[0].nombreMunicipio,
                                nombrePartido: '',
                                clavePartido: '',
                                logoPartido: '',
                                coalicion:false,
                                voto: totalVotos,
                                noesTotal: true,
                                writable: true
                            });
                        }      
                        
                        listaconTotal.push({
                            id:tab.id,
                            clave: tab.clave,
                            descripcion: tab.descripcion,
                            candidatos: regcands,
                            observaciones: observacion,
                            v:tab.v,
                            enumerable: tab.enumerable,
                            writable: true
                        });
                                       
                    
                });          
                // console.log(listaconTotal);

                
                responde([listaconTotal,listaLogoTipos]);
            }           
            
        
    });
}
    

export default ListaConTotalxCasilla;
