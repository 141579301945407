import ApiManager from "../constants/apiManager";

export const dataVotosxCasilla = async (casillaId) => {    
    try{

        const sesion = JSON.parse(localStorage.getItem('sesion'));
        const _token = sesion.token;

        let ruta = '/tipoActas/'+casillaId;
        const respondio = await ApiManager(ruta,{
            method:"GET",
            headers:{
                Authorization: `Bearer ${_token}`,
                'Content-Type':"application/json"                
            }            
        });
        // console.log(respondio);
        return respondio;

    }catch (error){
        return error;
    }
}

export default dataVotosxCasilla;