import { useState, useRef, useEffect  } from "react";
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import 'highcharts/modules/pattern-fill';
import Highcharts3D from "highcharts/highcharts-3d";
import HCExporting from 'highcharts/modules/exporting';
import HCExportData from 'highcharts/modules/export-data';
import HCStock from 'highcharts/modules/stock';
import SeccionesData from "./Secciones";

import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';

import '../../App.css';
import Data3dChart from "./data3dChart";
import dataVotosTotal from "../../api/dataVotosTotal";
import Menu from "../../componentes/menu/Menu";
import { TabsData } from "./TabsData";
import { LogosData } from "./LogosData";
import './index.css';


function Dash({navigation}) {
    //const dataLogin = useSelector((state) => state.dlogin);
    const dataGeneral = useSelector((state) => state.dCandidatos);
    const dataLogos = useSelector((state) => state.dLogotipo);
    const [tabSeleccionado, setTabSeleccionado] = useState(0);
    const [municipios, setMunicipios] = useState([]);
    const [logoTipos,setLogoTipos] = useState([]);     
    const [listaCompleta,setListaCompleta] = useState([]);
    const [votosAll,setVotosAll] = useState([]);
    const [vMunicipio,setVMunicipio] = useState(0);
    const [vSeccion,setVSeccion] = useState(0);
    const [vDistrito,setVDistrito] = useState(0);
    const [vCircun,setVCircun] = useState(0);
    const [vBar,setVBar] = useState(true);
    const [vLine,setVLine] = useState(false);
    const [votosOk,setVotosOk] = useState([]);
    const [votosFiltro,setVotosFiltro] = useState([]);
    const [dataChart,setDataChart] = useState(null);

    const [VotosReales, setVotosReales] = useState([
                 {
                     numero :0,
                     tipoVotoPos :0,
                     clavePartido : '',            
                     voto : 0,
                     descripcion: '',
                     municipioId:0,
                     nombreMunicipio:'',
                     distritoFederal:0,
                     distritoLocal:0,                     
                     fechaActualizacion : null        
                 }
             ]);

    let sesion = null;
    let dataLogin = null;
    let token = null;

    const chartContainerRef = useRef(null);           
    const chartContainerLRef = useRef(null);   

    let datosGrafica = null;

    sesion = JSON.parse(localStorage.getItem('sesion'));
    token = sesion.token;
    dataLogin = sesion;

    useEffect(() => {        

        setListaCompleta(TabsData);

    },[]);
   
    useEffect(() => {         
        const fetchData = async () => {        
            var param = {         
                TipoActaPos: tabSeleccionado+1
            }
            const _votosTodos = await dataVotosTotal(param);

            console.log(_votosTodos);

            let votosTodos = [];
            if (_votosTodos.data){
                votosTodos = _votosTodos.data.filter(i => i.tipoActaPos===param.TipoActaPos);
            }           

            
            setVotosAll(votosTodos);

            var tab = tabSeleccionado;

            // console.log(tab);
            // console.log(TabsData[tab]);
            

            
              //  if (votosTodos.length>0){                     
                    const _votosReales = [...VotosReales]; 

                    // console.log(votosAll);
                    var _numero = 1;
                    var votosParaGraficaxTipo = votosTodos
                                                .filter(item1 => item1.clavePartido !== "")
                                                //.filter(item => item.tipoActaId===tab);                        

                    // console.log('tab',tab);
                    // console.log(votosParaGraficaxTipo);


                    votosParaGraficaxTipo.map(rg => {                                                
                        let rutaLogo = '';
                        let dLogo = LogosData[0].findIndex((itm) => itm.clavePartido === rg.clavePartido);
                        if (dLogo>=0){
                            rutaLogo = LogosData[0][dLogo].logoPartido;
                        }
                        _votosReales.push({
                            numero :_numero,
                            tipoVotoPos : rg.tipoActaPos,
                            clavePartido : rg.clavePartido,
                            voto : rg.voto,
                            logoPartido: rutaLogo,
                            descripcion: rg.descripcion,
                            municipioId:rg.municipioId,
                            nombreMunicipio:rg.nombreMunicipio,
                            distritoFederal:rg.distritoFederal,
                            distritoLocal:rg.distritoLocal
                        });                
                    });               

                //if (votosParaGraficaxTipo.length>0){
                    Highcharts3D(Highcharts);
                    HCExporting(Highcharts);
                    HCExportData(Highcharts);                    

                    setVotosOk(_votosReales);
                    
                    const jsonChartData = Data3dChart(tab,_votosReales);
                    datosGrafica=jsonChartData;
                    setDataChart(jsonChartData);
                    cargaDataChart();                                        
                    
                //}
            
        }
        
        const tabSel = tabSeleccionado;

        let intervalo = null;
        if (localStorage.length>0){
            setListaCompleta(TabsData);
            setLogoTipos(LogosData);     


            fetchData();                                
    
            // intervalo = setInterval(fetchData, 90000);
    
            // return () => {
            //      clearInterval(intervalo);
            // };            
        }else{
            // clearInterval(intervalo);
            navigation.navigate('inicio');
        }

    },[tabSeleccionado]);  //[consultas]    

    const toggleTab = (index) => {
        setTabSeleccionado(index);  
    };        

    useEffect(() => {
        if (dataChart){
            cargaDataChart(dataChart);
        }
        
    },[dataChart])

    const cargaDataChart = (datosGrafica) => {
        
        if (datosGrafica){
           
                if (vBar){            
                    Highcharts.chart(chartContainerRef.current,  datosGrafica[0] );
                    //Highcharts.chart(chartContainerRef.current,  jsonChartData[0] );
                }
                if (vLine){
                    Highcharts.chart(chartContainerRef.current,  datosGrafica[1] );
                    //Highcharts.chart(chartContainerLRef.current, jsonChartData[1] );
                }
            
        }
        
    }

    const traeLogoPartido = (_clavePartido) => {
        
    }

    const renderButtonTab = (item, index) => {
        const isActive = tabSeleccionado === index;
        const isEnabled = item.clave === 'PEUM' || item.clave === 'GOB';
      
        return (
          <button
            key={`${item.id}-${item.clave}`}
            className={`tabs ${isActive && isEnabled ? 'active-tabs' : ''}`}
            disabled={!isEnabled}
            onClick={() => isEnabled && toggleTab(index)}
          >
            {item.descripcion}
          </button>
        );
    }

    // console.log(listaCompleta);

    const items = TabsData.map((item, index) => renderButtonTab(item, index));

    const tabContenido = listaCompleta.map((item,index) => (      
        <div key={item.id+'dv1'} className={tabSeleccionado === index ? "content active-content" : "content"}>                
            {/* {item.candidatos.map((itm,idx) => ( */}
                
                    <h3>grafica&nbsp;{index}</h3>
                    
                
            {/* ))}             */}
        </div>  
        
    ));    


    useEffect(() => {
        const jsonChartData = Data3dChart(tabSeleccionado+1,votosFiltro);        
        setDataChart(jsonChartData);
        cargaDataChart();                                    
    },[votosFiltro]);

    const handleBar = () => {
        setVLine(false);
        setVBar(true);
        cargaDataChart(dataChart);
    }

    const handleLine = () => {
        setVBar(false);
        setVLine(true);  
        cargaDataChart(dataChart);
    }

    
    return(        
        <div className="container">
            <Menu />
            <div className="bloc-tabs">  
                {items}        
            </div>                       
 
             <div className='imgContainDash'>
                <IconButton aria-label="BarChart" onClick={handleBar} >
                   <BarChartIcon className='iconPanelDash'  />
                </IconButton>
                <IconButton aria-label="LineChart" onClick={handleLine} >
                   <InsightsIcon className='iconPanelDash'  />
                </IconButton>
                <SeccionesData dataDash={votosOk} setVFiltro={setVotosFiltro} TipoActa={tabSeleccionado+1} />
             </div> 
             {/* <div className="containFiltrosDash">
                
             </div> */}




            <div className="content-tabs graficL" ref={chartContainerRef} ></div> 
            {/* <div className="content-tabs graficL" ref={chartContainerLRef}></div> */}
        </div>        
        
    );

}

export default Dash;