
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import { apiGetImg } from '../../api/apiGetImg';
import Config from '../../Config';
import "./index.css";

export default function ImgeView(props){
    const {fuente,wImg, setWImg, rImg, setRImg, imgActa} = props;
    const [wValor,setWValor] = useState(wImg);
    const [rValor,setRValor] = useState(rImg);
    const [imagenExiste, setImagenExiste] = useState(true);

    // console.log(fuente);
    // console.log(rImg);

    if (fuente===null && imgActa===null) return;


    let rutaIMG = fuente !== null ? Config.viw+fuente : null;   

    if (imgActa!==null){
        rutaIMG = imgActa;
    }   
    

    const handleWMas = () => {
        const wV = wValor+200;        
        setWValor(wV);
        setWImg(wV);
    }

    const handleWMenos = () => {
        const wV = wValor>400 ? wValor-200 : 1300;
        setWValor(wV);
        setWImg(wV);
    }

    const handleRotate = () => {
        const _rota = rValor>270 ? 0 : rValor+45;
        setRValor(_rota);
        setRImg(_rota)
    }
    
    const anchoImagen = wValor.toString() + 'px';
    const rotacion = 'rotate('+rValor.toString() + 'deg)' ;
   
    const imgSource = {        
        width:anchoImagen,        
        transform: rotacion
    }
  
    const verificarImagen = () => {
        if (rutaIMG) {
          const imagen = new Image();
          imagen.src = rutaIMG;
  
          imagen.onload = () => {
            setImagenExiste(true);
          };
  
          imagen.onerror = () => {
            setImagenExiste(false);
          };
        } else {
          setImagenExiste(false);
        }
      };
  
      verificarImagen();

    return(
        <div className='imgContain'>
            {imagenExiste  && 
                <>
                    <div className='imgPanel'>
                        <IconButton aria-label="ZoomIn" onClick={handleWMas} >
                            <ZoomInIcon className='iconPanel'  />
                        </IconButton>
                        <IconButton aria-label="ZoomOut" onClick={handleWMenos}>
                            <ZoomOutIcon className='iconPanel' />
                        </IconButton>
                        <IconButton aria-label="Rotate" onClick={handleRotate} >
                            <Rotate90DegreesCwIcon className='iconPanel'  />
                        </IconButton>                
                    </div>
                    <div className="imgFteContain">

                       <img  src={rutaIMG}  alt="." style={imgSource}  />     
                    </div>            
                </>
            }
            {!imagenExiste && <span>La imagen no esta disponible</span>}
        </div>
        
    );

}