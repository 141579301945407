

import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Autocomplete from '@mui/material/Autocomplete';
import { apiGet } from "../../../api/apiGet";
import "./index.css";


export default function SeccionesData(props){
    const {dataDash,setVFiltro, TipoActa} = props;
    const [municipio,setMunicipio] = useState(null);
    const [municipioAll,setMunicipioAll] = useState([]);
    const [dataMunicipio,setDataMunicipio] = useState([]);
    const [distritoFederal,setDistritoFederal] = useState(null);
    const [dataDistritoFed,setDataDistritoFed] = useState([]);
    const [distritoLocal,setDistritoLocal] = useState(null);
    const [dataDistritoLocal,setDataDistritoLocal] = useState([]);


    useEffect(() => {
        const getMunicipios = async () => {
            const ruta = "/secciones";
            const dataMunicipio = await apiGet(ruta);
            
            const aMunic = dataMunicipio.data;
            setMunicipioAll(aMunic);
            // const datoMunicipio = aMunic.reduce((acc,curr) => {
            //     return [...acc, {value: curr.id, label: curr.nombreMunicipio}];
            // },[]);     
            
            const datoMunicipio = Array.from(
                new Set(aMunic.map(({ nombreMunicipio }) => nombreMunicipio))
              ).map((nombreMunicipio) => {
                const municipio = aMunic.find((m) => m.nombreMunicipio === nombreMunicipio);
                return { value: municipio.municipioId, label: municipio.nombreMunicipio };
              });

            setDataMunicipio([{ value:'-1',label:'- TODOS -' }, ...datoMunicipio]);


        }

        if (dataDash.length>1){
            getMunicipios();
        }
        
    },[dataDash]);

    useEffect(() => {
        // console.log(municipio);
        if (municipio){
            if (municipio.value!=='-1'){
                const data = municipioAll.filter(i => i.municipioId === municipio.value );                

                const dFed = Array.from(
                    new Set(data.map(({ distritoFederal }) => distritoFederal))
                ).map((distritoFederal) => {
                    const municipio = data.find((m) => m.distritoFederal === distritoFederal);
                    return { value: municipio.distritoFederal, label: municipio.distritoFederal };
                });
                setDataDistritoFed([{ value:'-1',label:'- TODOS -' }, ...dFed]);
                setDistritoFederal({ value:'-1',label:'- TODOS -' });

                const dLoc = Array.from(
                    new Set(data.map(({ distritoLocal }) => distritoLocal))
                ).map((distritoLocal) => {
                    const municipio = data.find((m) => m.distritoLocal === distritoLocal);
                    return { value: municipio.distritoLocal, label: municipio.distritoLocal };
                });
                setDataDistritoLocal([{ value:'-1',label:'- TODOS -' }, ...dLoc]);
                setDistritoLocal({ value:'-1',label:'- TODOS -' })

                //   console.log(dFed);

    
            }    
        }
        
    },[municipio])
    

    const handleMunicipio = (event,newValue) => {
        setMunicipio(newValue)    

    }

    const handleDistroFed = (event,newValue) => {
        setDistritoFederal(newValue)      
        
    }

    const handleFiltraFederal = () => {

        if (municipio!==null && distritoFederal !==null){
            console.log(TipoActa , municipio, distritoFederal);
            // console.log(dataDash);
    

            if (municipio.value!=='-1'){
                const xMunicipio = dataDash.filter(i => i.nombreMunicipio === municipio.label);
                
                if (distritoFederal.value!=='-1'){
                    const xDistrito = xMunicipio.filter(i=>i.distritoFederal===distritoFederal.value);                    
                    console.log(xDistrito);
                    setVFiltro(xDistrito);
                }else{                  
                    setVFiltro(xMunicipio);
                }
            }            
            
        }
        
    }

    const handleDistroLoc = (event,newValue) => {
        setDistritoLocal(newValue)
    }

    const handleFiltraLocal = () => {
       
        if (municipio!==null && distritoLocal !==null){
            console.log(TipoActa , municipio, distritoLocal);
            const xMunicipio = dataDash.filter(i => i.nombreMunicipio === municipio.label);

            if (distritoLocal.value!=='-1'){
                const xDistritoLocal = dataDash.filter(i => i.nombreMunicipio === municipio.label && i.distritoLocal===distritoLocal.value);
                console.log(xDistritoLocal);
                setVFiltro(xDistritoLocal);
            }else{                
                setVFiltro(xMunicipio);
            }

            
        }

        

        


    }

    return(
        <>
           {dataDash.length>1 && 
                <Autocomplete
                    disablePortal                                
                    value={municipio}
                    options={dataMunicipio}
                    onChange={handleMunicipio}
                    sx={{ width: 270, borderColor:"#CCC", marginRight:4, '& .MuiOutlinedInput-root': {fontSize:'10pt'} }}
                    renderInput={(params) => <TextField {...params} label=" Municipio   " />}                          
                />
           } 
            {dataDash.length>1 && TipoActa===5 && 
                <>
                    <Autocomplete
                        disablePortal                                
                        value={distritoFederal}
                        options={dataDistritoFed}
                        onChange={handleDistroFed}
                        sx={{ width: 170, borderColor:"#CCC", marginRight:4, '& .MuiOutlinedInput-root': {fontSize:'10pt'} }}
                        renderInput={(params) => <TextField {...params} label=" Distrito Federal   " />}                          
                    />
                    <IconButton aria-label="BarChart" className="iconContainer" onClick={handleFiltraFederal} >
                        <FindInPageIcon className='iconFindCasillas'  />
                    </IconButton>
                </>
             }
              {dataDash.length>1 && TipoActa===6 && 
                <>
                    <Autocomplete
                        disablePortal                                
                        value={distritoLocal}
                        options={dataDistritoLocal}
                        onChange={handleDistroLoc}
                        sx={{ width: 170, borderColor:"#CCC", marginRight:4, '& .MuiOutlinedInput-root': {fontSize:'10pt'} }}
                        renderInput={(params) => <TextField {...params} label=" Distrito Local   " />}                          
                    />
                    <IconButton aria-label="BarChart" className="iconContainer" onClick={handleFiltraLocal} >
                        <FindInPageIcon className='iconFindCasillas'  />
                    </IconButton>
                </>
             } 
                
            
        </>

    );


}