import ApiManager from "./../constants/apiManager";

export const dataVotosLista = async _token => {    
    try{
        let ruta = '/tipoActas/0';
        const respondio = await ApiManager(ruta,{
            method:"GET",
            headers:{
                Authorization: `Bearer ${_token}`,
                'Content-Type':"application/json"                
            }            
        });
        // console.log(respondio);
        return respondio;

    }catch (error){
        return error;
    }
}

export default dataVotosLista;