import ApiManager from "./../constants/apiManager";

export const dataObserva = async (_token, ruta) => {
    try{
        
        const respondio = await ApiManager(ruta,{
            method:"GET",
            headers:{
                Authorization: `Bearer ${_token}`,
                'Content-Type':"application/json"                
            }            
        });
        return respondio;
    }catch (error){
        return error;
    }
}

export default dataObserva;