import axios from "axios";
import Config from "../Config";


export function apiObservaciones(metodo,formDato){
    return new Promise( async (resolve,reject) => {
        const API_URL = Config.api+'/actas/Observaciones';                
        // metodo = 'post' ó 'put' 

        let sesion = localStorage.getItem('sesion');
        let sesionJS = JSON.parse(sesion);      
        let _token = sesionJS.token;

         console.log(formDato);

        const guarda = {
            "id": "m5GYBNZ3",
            "observaciones": "actualizo",
            "tipoObservacionId": "YwveKGyD",
            "incidenciaId": "YwveKGyD",
            "representante": "YwveKGyD",
            "asistio": false,
            "tipoActaId": "2jNbjNne",
            "actaDtos": [
              {
                "id": "2jNbjNne",
                "tipoActaId": "2jNbjNne",
                "partidoId": "R91DeGzM",
                "candidatoId": "2jNbjNne",
                "votos": 10,
                "tipoActaPos": 1
              },
              {
                "id": "BzN9QNMq",
                "tipoActaId": "2jNbjNne",
                "partidoId": "2jNbjNne",
                "candidatoId": "BzN9QNMq",
                "votos": 11,
                "tipoActaPos": 1
              }
            ]
          }
          

        const config = {
            method: metodo,
            url: API_URL,
            headers: {
               Authorization: `Bearer ${_token}`,
              'Content-Type': 'application/json',
            },
            data: formDato // JSON.stringify(formDato),
          };

        console.log(config);

        await axios( config )
        .then(response => {
            resolve(response);                        
        })
        .catch(erro => {
            console.log(erro);
            reject(erro);
        });
    } );
}