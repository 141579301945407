import { useState, useRef, useEffect  } from "react";

import IconButton from "@mui/material/IconButton";
import BackupIcon from '@mui/icons-material/Backup';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CalculateIcon from '@mui/icons-material/Calculate';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import ProgressCirc from '../../componentes/ProgressCirc/ProgressCirc';
import { apiObservaPDF } from "../../api/apiObservaPDF";
import { useNavigate } from 'react-router-dom';
import dataObserva from "../../api/dataObserva";
import { ObservaUpdate } from "./ObservaUpdate";
import ListaConTotal from "../../componentes/tabs/dsVotos/ListaConTotal";
import DialogAlert from "../../componentes/Dialog/DialogAlert";
import { VisorPdf } from "./VisorPdf";
import ImgeView from "../../componentes/ImageView/Index";
import Menu from "../../componentes/menu/Menu";
import CboSecciones from "./CboSecciones/Index";
import dataVotosxCasilla from "../../api/dataVotosxCasilla";
import ListaConTotalxCasilla from "../../componentes/tabs/dsVotos/ListaConTotalxCasilla";
import "../../App.css";



function Tactas() {            
    const [listacompleta,setListacompleta] = useState([]);  
    const [ltaCompleta,setLtaCompleta] = useState([]);  
    const [toggleState, setToggleState] = useState(-1); 
    const [tipoActaId_, setTipoActaId_] = useState(''); 
    const [totalVotos, setTotalVotos] = useState(0); 
    const [asistio, setAsistio] = useState(true); 
    const [representante, setRepresentante] = useState('');
    const [incidencia, setIncidencia] = useState([]);
    const [idincidencia, setIdIncidencia] = useState('');
    const [idObservacion,setIdObservacion] = useState('');
    const [tipoObservaciones,setTipoObservaciones] = useState([]);
    const [idtipoObservaciones,setIdTipoObservaciones] = useState('');
    const [observaciones,setObservaciones] = useState('');
    const [fileActa, setFileActa] = useState(null);
    const [guardo, setGuardo] = useState(0); 
    const [filePdf, setFilePdf] = useState(null);
    const [uploadPercent,setUploadPercent] = useState(0);
    const [vencioToken,setVencioToken] = useState(false);
    const [wdPdf,setWdPdf] = useState('0');
    const [gridStile,setGridStile] = useState('gridd-container');
    const [pdfContainerStilo,setPdfContainerStilo] = useState('pdf-container');
    const [pdfViewStilo,setPdfViewStilo] = useState('pdf-View');
    const [pdfIconStilo,setPdfIconStilo] = useState('pdf-IconStilo');
    const [closePDF,setClosePdf] = useState(false);
    const [wdContent,setWdContent] = useState('95%');    
    const [imgRender,setImgRender] = useState(0);
    const [wImagen,setWImagen] = useState(1300);
    const [rImagen,setRImagen] = useState(0);
    const [vSeccion,setVSeccion] = useState('');
    const [vCasilla,setVCasilla] = useState('');
    const [imageActa,setImageActa] = useState(null);
    const [imageUpActa,setImageActaUp] = useState(null);
    const [vItems,setVItems] = useState(null);
    const [vTabContenido,setVTabContenido] = useState(null); 
    const [divHtmlCoalision,setDivHtmlCoalision] = useState(null);
    const [divCoalision,setDivCoalision] = useState(false);
    const [votosCoali,setVotosCoali] = useState(0);
    const [votoCoali,setVotoCoali] = useState(0);
    const [campoCard,setCampoCard] = useState('');


    const [alerta,setAlerta] = useState({
        mensaje:'',
        titulo:''
    })

    const inputVotoRef = useRef(null);

    const navigate = useNavigate();   

    let sesion = null;
    let dataLogin = null;
    let token = null;
    
    sesion = JSON.parse(localStorage.getItem('sesion'));
    token = sesion.token;
    dataLogin = sesion;

    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      }

    const alertar = (titulo,mensaje) =>{
        const _alerta = {...alerta};
        _alerta.mensaje=mensaje;
        _alerta.titulo=titulo;
        setAlerta(_alerta);
    }

    const toggleTab = (index) => {              
        // console.log(index);
        setToggleState(index);
        setTotalVotos(0);
        
        ObservaDataGet(index);
        //ObtieneDatos_Observaciones(index).then(respondio => {
        //    sumaVotosTab(listacompleta,index).then(cuantosvotos => {
        //         setTotalVotos(cuantosvotos);
        //    });    
        //});
        
    };

    const ObtieneDatos_Observaciones = async (_tipoCandidato) => {
        
        //DataFieldClean();
        const tipoCandidato = _tipoCandidato+1;            
        let ruta = '/actas/Observaciones/';
        const resObserva = await dataObserva(token,ruta);
        const vencio = tieneStatus(resObserva[0]);
        if (vencio){
            console.log('va a login por vencmiento de token');
            setVencioToken(true);               
            return;
        }
        if (resObserva.data){

            const  datosObservacions = resObserva.data;

            const datosObservaciones = datosObservacions.filter(i => i.casillaId === vCasilla && i.tipoActaId === toggleState);

            console.log(vCasilla);
            console.log(datosObservaciones);

            const Observaciones_TipoCandidato = datosObservaciones.filter(i => i.tipoActaId === tipoCandidato)                
            const observaTipoCandidato = Observaciones_TipoCandidato[0];                

            if (observaTipoCandidato){                        
                setFilePdf(observaTipoCandidato.file);
                setImgRender(1);
                setIdObservacion(observaTipoCandidato.id);
                setAsistio(observaTipoCandidato.asistio);
                setRepresentante(observaTipoCandidato.representante);
                setIdIncidencia(observaTipoCandidato.incidenciaId);
                setObservaciones(observaTipoCandidato.observaciones);
                setIdTipoObservaciones(observaTipoCandidato.tipoObservacionId);
            }
        }
} 


const tieneStatus = (obj) => {        
    if (obj){
        if (obj.status !== null){
            if (obj.status !== undefined){
                if (typeof obj.status === "string"){
                    if (obj.status.contains('code 401')){
                        return true;
                    }
                }
            }
        }   
    }
    return false; // obj && obj.status !== null && obj.status !== undefined && obj.status !== "";          
}


const DataFieldClean = () => {
    setFilePdf(null);                            
    setIdObservacion('');
    setAsistio(false);
    setRepresentante('');
    setIdIncidencia('');
    setObservaciones('');
    setIdTipoObservaciones('');
}

const ObservaClean = () =>{
    setTipoActaId_('');
    setFilePdf('');
    setImgRender(0);
    setIdObservacion('');
    setAsistio(false);
    setRepresentante('');
    setIdIncidencia('');
    setObservaciones('');
    setIdTipoObservaciones('');
}

const handleFile = (e) => {

    console.log(e.target.files[0]);    
    
     if (e.target.files && e.target.files[0]) {
        console.log(e.target.files[0]);
        setImageActa(URL.createObjectURL(e.target.files[0]));
        setImageActaUp(e.target.files[0]);

    }        


};

const handleObserva = (event) => {
    setObservaciones(event.target.value);
}

const handleRepresentante = (event) => {
    setRepresentante(event.target.value);
}

const handleObservaTipo = (event) => {
    setIdTipoObservaciones(event.target.value);
}

const handleKeyDown = (event) => {        
    if (event.keyCode === 13) {                  
        console.log('Enter presionado ');
    }
};

const handleCheckAsistio = () =>{
    setAsistio(!asistio);
}

const handleIncidencia = (event) => {
    setIdIncidencia(event.target.value);
}

    const handleGuardar = () => {
        if (representante===''){
            alertar("Observaciones","Debe teclear el representante...");
            return;
        }
        if (idincidencia===''){
            alertar("Observaciones","Debe seleccionar una incidencia...");
            return;
        }
        if (idtipoObservaciones===''){
            alertar("Observaciones","Debe seleccionar un tipo de observación...");
            return;
        }
        if (observaciones===''){
            alertar("Observaciones","Debe teclear una observación...");
            return;
        }

        const confirmed = window.confirm('¿Estás seguro de que deseas actualizar..?');
        if (confirmed) {          

            // if (idtipoObservaciones===''){
            //     setIdTipoObservaciones(tipoObservaciones[0].id);
          
            //     const idobser = tipoObservaciones[0].id;
          
            //     setIdTipoObservaciones(idobser);
          
            // }

           

            const camposObserva = {
                observaciones: observaciones,
                tipoObservacionId: idtipoObservaciones,
                incidenciaId: idincidencia,
                casillaId: vCasilla.value,
                representante: representante,                
                asistio: asistio,
                seccionId: vSeccion.value,
                idObservacion: idObservacion
            }            


            ObservaUpdate(toggleState,listacompleta,camposObserva)
            .then(respondio => {
                console.log(respondio[0].status);                
                const regreso = respondio[0].status;                
                if (tieneStatus(respondio[0])){
                    setVencioToken(true);
                    return;
                }
                if (regreso===200){
                    if (respondio[1]==='post'){
                        const _idObservacion = respondio[0].data; //IdRegresado..
                        handlePdfUpload(_idObservacion);
                    }else{
                        handlePdfUpload(idObservacion);
                    }

                    setListacompleta([]);
                    ObservaClean();
                    setToggleState(-1);

                }
                //ObservaDataGet(toggleState);
                
                // ObtieneDatos_Observaciones(toggleState).then(respondio => {
                //     console.log(idObservacion);
                //     alertar("Observaciones","Se actualizaron correctamente los datos de observación...");
                // })
            })
            .catch(erro => {
                console.log(erro);
                alertar("Observaciones","Ocurrio un error al actualizar los datos de observación...");
            });        
            
        }
    }

    const sumaVotosTab = async (listaTotal,index) => {
        return new Promise(regresaTotal => {            

            // console.log(listaTotal);
            let totaldevotos = 0;
            if (listaTotal.length>0){
                let listaTipoVoto = listaTotal[index];            
                            let listacandidatos = listaTipoVoto.candidatos.filter(i=>i.id!=='totalId' && i.clavePartido!=='NULO');
                            // console.log(listacandidatos);
                            totaldevotos = listacandidatos.reduce((sum, item) => {
                                return sum + parseInt(item.voto);
                            }, 0);
            }            
            regresaTotal(totaldevotos);
        });        
    }

    const sumaVotos = async (listaTotal) => {
        return new Promise(regresaTotal => {            

            let listaTipoVoto = listaTotal[toggleState];            
            let totaldevotos = 0;
            if (listaTipoVoto){
                if (listaTipoVoto.candidatos){
                    let listacandidatos = listaTipoVoto.candidatos.filter(i => i.id!=="totalId" && i.clavePartido!=='NULO');
                    totaldevotos = listacandidatos.reduce((sum, item) => {
                        return sum + parseInt(item.voto);
                    }, 0);
                }
            }
            regresaTotal(totaldevotos);
        });        
    }

    useEffect(() => {
        setImgRender(2);
    },[imgRender]);

    useEffect(() => {
        if (vencioToken){
            localStorage.clear()
            navigate('/');
        }        
    },[vencioToken]);
  

    const handlePdfUpload = (_idObservacion) => {              

        if (imageUpActa && _idObservacion){
            let __tipoActaId="";
            if (toggleState===4){
                __tipoActaId="2jNbjNne";
            }
            if (toggleState===5){
                __tipoActaId="LPvlpvnk";
            }

            const datForma = new FormData();
            datForma.append('id',_idObservacion);         
            datForma.append('file',imageUpActa);
            datForma.append('casillaId',vCasilla.value);
            datForma.append('seccionId',vSeccion.value);
            datForma.append('tipoActaId',__tipoActaId);

            setUploadPercent(0);

            apiObservaPDF(_idObservacion,datForma,setUploadPercent)
            .then(respondio => {
                console.log(respondio.status);
                setUploadPercent(100);
                // ObtieneDatos_Observaciones(toggleState)
                setImageActa(null);
                setImageActaUp(null);
                alertar('Carga de Acta','El Acta se adjunto correctamente...');

            })
            .catch(erro => {                
                console.log(erro);
                alertar('Carga de Acta','Ocurrio un error al cargar el Acta...');
            })
        }     
    }
    
    const handleInputChange = (index,id,event) => {
        let newListaVoto = [...listacompleta];
        
        // console.log(newListaVoto);

        newListaVoto[index].candidatos[id].voto = parseInt(event.target.value);
        setListacompleta(newListaVoto);
        setTotalVotos(0);
        sumaVotos(newListaVoto).then(cuantosvotos => {
            setTotalVotos(cuantosvotos);
       });
      };

    const handleInputFocus = (event) => {        
        event.target.select();        
    };

    // let items = null;

    const handleClickPdf = () => {
       
        console.log(gridStile);
        if (gridStile==='gridd-container'){
            setGridStile('gridd-containerOn');
            setPdfContainerStilo('pdf-containerOn');
            setPdfViewStilo('pdf-ViewOn');
            setPdfIconStilo('pdf-IconStiloOn');
            setClosePdf(true);
        }else{
            setGridStile('gridd-container');
            setPdfContainerStilo('pdf-container');
            setPdfViewStilo('pdf-View');
            setPdfIconStilo('pdf-IconStilo');
            setClosePdf(false);
        }
       
    }      

    const contentCardsStyle = {
        width:wdContent
    }
       
    
    const handleCasillaValue = async (event,newVal) => {
        
        setListacompleta([]);
        // setImageActa(null);
        // setImageActaUp(null);

        ObservaClean();     
        setToggleState(-1);

        setVCasilla(newVal);
        const votosxcasilla = await dataVotosxCasilla(newVal.value);
        // console.log(votosxcasilla);
        // if (tieneStatus(votosxcasilla)){
        //     setVencioToken(true);
        //     return;
        // }

        // const dataTabs = votosxcasilla.data.filter(i=>i.clave==='PEUM' || i.clave==='GOB' );        

        const dataTabs = votosxcasilla.data;          

        ListaConTotalxCasilla(dataTabs).then(listaTotal => {
            if (tieneStatus(listaTotal[0])){
                setVencioToken(true);
            }else{
                //  console.log(listaTotal[0]);              

                setListacompleta(listaTotal[0]);

                setTotalVotos(0);
                sumaVotos(listaTotal[0]).then(cuantosvotos => {
                    setTotalVotos(cuantosvotos);
                });

                // console.log(listacompleta);

                let ruta = '/tipoobservaciones/';
                dataObserva(token,ruta).then((resTipoObservaciones) => {
                    // console.log(resTipoObservaciones);
                    let _tipoObservaciones = {id:'', descripcion:'-- SELECCIONE --'};

                    let _tobserva = resTipoObservaciones.data;
                    _tobserva.unshift(_tipoObservaciones);
                    
                    setTipoObservaciones(resTipoObservaciones.data);
                    
                    let ruta = '/incidencias/';                
                    dataObserva(token,ruta).then( async (resIncidencias) => {
                        if (resIncidencias.data){                                
                            let _incidencia = {id:'', descripcion:'-- SELECCIONE --'};

                            let _inci = resIncidencias.data;
                            _inci.unshift(_incidencia)

                            setIncidencia(resIncidencias.data);  
                            // console.log(toggleState);
                            //await ObtieneDatos_Observaciones(toggleState);
                            
                            ObservaDatosInicio(listaTotal[0],0);


                        }                        
                    });
                });
            }
        });            

    }

    const ObservaDatosInicio = (listacero,pos) => {
        // console.log(listacero, pos);


         sumaVotosTab(listacero,pos).then(cuantosvotos => {
                setTotalVotos(cuantosvotos);
         });   

        //  console.log(listacero);
         if (listacero.length>0){            
            if (listacero[pos]){
                const objObserva = listacero[pos]["observaciones"];
                if (objObserva){
                    const __observa = objObserva;

                    setTipoActaId_(__observa.tipoActaId);
                    setFilePdf(__observa.file);
                    setImgRender(1);
                    setIdObservacion(__observa.id);
                    setAsistio(__observa.asistio);
                    setRepresentante(__observa.representante);
                    setIdIncidencia(__observa.incidenciaId);
                    setObservaciones(__observa.observaciones);
                    setIdTipoObservaciones(__observa.tipoObservacionId);
                }
            }            
         }        
    }

    const ObservaDataGet = (pos) => {
        // console.log(listacompleta, pos);


         sumaVotosTab(listacompleta,pos).then(cuantosvotos => {
                setTotalVotos(cuantosvotos);
         });   

        //  console.log(listacompleta);
         if (listacompleta.length>0){            
            if (listacompleta[pos]){
                const objObserva = listacompleta[pos]["observaciones"];
                if (objObserva){
                    const __observa = objObserva;

                    setTipoActaId_(__observa.tipoActaId);
                    setFilePdf(__observa.file);
                    setImgRender(1);
                    setIdObservacion(__observa.id);
                    setAsistio(__observa.asistio);
                    setRepresentante(__observa.representante);
                    setIdIncidencia(__observa.incidenciaId);
                    setObservaciones(__observa.observaciones);
                    setIdTipoObservaciones(__observa.tipoObservacionId);
                }
            }            
         }        
    }


    // console.log(listacompleta);

    const renderButtonTab = (item, index) => {
        const isActive = toggleState === index;
        const isEnabled = item.clave === 'PEUM' || item.clave === 'GOB';
      
        return (
          <button
            key={`${item.id}-${item.clave}`}
            className={`tabs ${isActive && isEnabled ? 'active-tabs' : ''}`}
            disabled={!isEnabled}
            onClick={() => isEnabled && toggleTab(index)}
          >
            {item.descripcion}
          </button>
        );
    }

    // console.log(listacompleta);

    const items = listacompleta.map((item, index) => renderButtonTab(item, index));

    function handleSumaCoalision(event,index,id){
        
        console.log(event);

        if (divCoalision){
            setDivCoalision(false);
            setVotoCoali(0);
            setVotosCoali(0);
            setCampoCard('');
        }else{
            let newListaVoto = [...listacompleta];
        
            console.log(newListaVoto);
    
            const votoActual = newListaVoto[index].candidatos[id].voto;
            setDivCoalision(true);
            setVotosCoali(parseInt(votoActual));
            const concatenado = index+','+id;
            setCampoCard(concatenado);
        }               
    }

   
    const tabContenido = listacompleta.map((item,index) => (          
        <div key={item.id+'dv1'} className={toggleState === index ? "content active-content" : "content"}  style={contentCardsStyle}>    
            
            <div key={item.id+'dv2'} className="bodycard">
                <div key={item.id+'dv3'} className="bcontainer"> 
                    {item.candidatos.map((itm,idx) => (
                        <div key={itm.id+itm.partidoId+'card9'} className={ !itm.noesTotal ? "card9" : "card9t" }>
                            { !itm.noesTotal &&
                                <div key={itm.id+itm.partidoId+'imgBx9'} className="imgBx9">
                                    <img key={itm.id+itm.partidoId+'img'} src={itm.logoPartido} alt="" />
                                </div>
                            }
                            <div key={itm.id+itm.partidoId+'content9'} className={ !itm.noesTotal ? "content9" : "content9t" }>       
                                <h5 key={itm.id+itm.partidoId+'h5'}>{itm.nombreCompleto}</h5>                                                                                                 
                                <p key={itm.id+itm.partidoId+'p'}>                                                         
                                    <input 
                                        id={item.id+'|'+index+'|'+itm.id+'|'+idx}
                                        className={ !itm.noesTotal ? "voto" : "votoTotal" }
                                        value={ !itm.noesTotal ? !itm.voto  ? 0 : itm.voto : totalVotos}
                                        onChange={(event) => handleInputChange(index,idx,event)}
                                        key={itm.id+itm.partidoId+'number'}
                                        onKeyDown={handleKeyDown}
                                        onFocus={(event) => handleInputFocus(event)}
                                        type={itm.noesTotal ? "text" : "number" }
                                        disabled={itm.noesTotal}
                                        ref={inputVotoRef}
                                    />             
                                    {itm.coalicion && 
                                        <IconButton aria-label="BarChart" className="iconCoalisionContainer" onClick={(event) => handleSumaCoalision(event,index,idx)} >
                                            <CalculateIcon className='calCoalision'  />
                                        </IconButton>
                                    }                                         

                                </p>
                            </div>
                        </div>
                    ))} 
                </div>
            </div>
        </div>
    ));    


    // console.log(listacompleta);

    const haycandidatos = () => {
        let rgsa = false;
        if (listacompleta[toggleState]){
            const listaC = listacompleta[toggleState];
            if (listaC.candidatos){
                if (listaC.candidatos.length>0){
                    rgsa = true;
                }
            }
        }
        return rgsa;
    }

    const handleNuevoValorCoali = (event) => {
        setVotoCoali(event.target.value);
    };

    const handleAddVotoCoalision = () => {
        // console.log(campoCard, votoCoali, votosCoali);

        let newListaVoto = [...listacompleta];
        
        const posCampo = campoCard.split(',');
        const index = posCampo[0];
        const id = posCampo[1];

        console.log(newListaVoto);

        const acumulado = parseInt(votosCoali)+parseInt(votoCoali);

        newListaVoto[index].candidatos[id].voto = acumulado;
        setListacompleta(newListaVoto);
        setTotalVotos(0);
        sumaVotos(newListaVoto).then(cuantosvotos => {
            setTotalVotos(cuantosvotos);
       });

       setVotosCoali(0);
       setVotoCoali(0);
       setCampoCard('');
       setDivCoalision(false);

    };

    return(                
        <div className="container">
            <Menu />
            {alerta.mensaje!=='' && <DialogAlert mensaje={alerta.mensaje} titulo={alerta.titulo} setUploadPercent={setUploadPercent} alerta={alerta} setAlerta={setAlerta} /> }
            <div className="casillaDato">
                {/* <span className="LtipoCasilla">Sección:</span>
                <span className="tipoCasilla">{dataLogin.seccion}</span>
                <span className="tipoCasilla">{dataLogin.casilla}</span>
                <span className="LubicaCasilla">Ubicación:</span><span className="ubicaCasilla">{dataLogin.ubicacion}</span> */}
                <CboSecciones valor={vSeccion} setSeccion={setVSeccion} casilla={vCasilla} setCasilla={setVCasilla} handleCasillaV={handleCasillaValue} setListaTotal={setListacompleta}  />
                 <table className="tablaCasillaDato">
                    <tr>
                        <td width="30%" className='labelFile'>
                            Adquirir Acta
                        </td>                    
                        <td width="60%">
                            <input type="file" onChange={handleFile} accept="image/*" className='archivo' />
                        </td>
                        {/* <td>                            
                            <IconButton size="small" onClick={handlePdfUpload}>
                                <BackupIcon fontSize="small"  />
                            </IconButton>
                        </td> */}
                        <td width="10%">
                             {closePDF && <CloseIcon onClick={handleClickPdf} className={pdfIconStilo} />}
                            {!closePDF && <ImageIcon onClick={handleClickPdf} className={pdfIconStilo} />}
                        </td>
                    </tr>
                </table>
            </div>  
            
               
                <div className="bloc-tabs">  
                   {items && tabContenido.length>0 &&  items }
                </div>
                <div className='main-container'>
                    <div className={gridStile}>
                        <div className='tabs-container'>                                     
                            {items && tabContenido.length>0 && tabContenido  }
                        </div>
                        {haycandidatos() && 
                            <div className='obs-container'>
                                <div className="grid-itemObserva">
                                    <span className="observaTitulo">Observaciones</span>
                                </div>
                                <div className="grid-itemObserva">
                                    <table>                                        
                                        <tr> 
                                            <td colSpan="2" class="fileexterno"  style={{ padding:'8px', fontFamily:'Arial', fontSize:'.7rem', color:'#000' }}>
                                                {filePdf}
                                            </td>
                                            <td>
                                                {uploadPercent>0 && 
                                                    <ProgressCirc value={uploadPercent} />
                                                }
                                            </td>
                                        </tr>
                                    </table>                                    
                                </div>
                                <div className="grid-itemObserva">
                                    <div className="LRepresentante">
                                        <label>Representante:</label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={asistio}
                                                onChange={handleCheckAsistio}
                                            />&nbsp; Asistio
                                        </label>
                                    </div>
                                    <textarea className="TRepresenta" rows={4} cols={42} value={representante} onChange={handleRepresentante} />
                                </div>                    
                                <div className="grid-itemObserva">
                                    <div className="LRepresentante">
                                        <label>Tipo Inicidencia:</label>
                                    </div>
                                    <select className="cboIncidencia" value={idincidencia} onChange={handleIncidencia}>
                                        {incidencia.map(opcion => (
                                            <option key={opcion.id}  value={opcion.id}>{opcion.descripcion}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="grid-itemObserva">
                                    <div className="LRepresentante">
                                        <label>Tipo Observación:</label>
                                    </div>
                                    <select className="cboIncidencia" value={idtipoObservaciones} onChange={handleObservaTipo}>
                                        {tipoObservaciones.map(opcion => (
                                            <option key={opcion.id}  value={opcion.id}>{opcion.descripcion}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="grid-itemObserva" >
                                    <div className="LRepresentante" >
                                        <label>Observaciones:</label>                                                                                  
                                    </div>                        
                                    <textarea className="TObserva" rows={10} cols={42} value={observaciones} onChange={handleObserva} />                        
                                </div>
                                <div className="grid-itemObserva">                        
                                    <button id="gdaObserva" className="btnGdaObserva" onClick={handleGuardar}>Guardar</button>                        
                                </div>        
                            </div>
                        }
                    </div>            
                   
                        <div key={generateRandomString(17)} className={pdfContainerStilo} >                           
                            <div key={generateRandomString(18)} className={pdfViewStilo} >
                                {/* <VisorPdf pdfUrl={filePdf} /> */}
                                <ImgeView fuente={filePdf} wImg={wImagen} setWImg={setWImagen} rImg={rImagen} setRImg={setRImagen} imgActa={imageActa} />
                            </div>
                        </div>
                   
                </div>  

                {divCoalision && 
                    <div className="divSumaCoalision">
                        <label>Cantidad</label>
                        <input type="number" className="coaliNumber" value={votoCoali} onFocus={(event) => handleInputFocus(event)} onChange={(event) => handleNuevoValorCoali(event)} />
                        <input type="button" className="coaliBoton" value={'SUMAR'} onClick={handleAddVotoCoalision} />
                        <label className="coaliVotosAntes">{votosCoali}</label>
                    </div>
                }  
              
                      
            
        </div>        
        
    );

}

export default Tactas;

