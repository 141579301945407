import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    votoGeneral : 
    [
        {
            tipoVotoPos:null,
            tipoVotoId:null,
            descripcion: '',    
            candidatoid: null,
            nombreCompleto: '',
            nombreMunicipio: '',
            clavePartido: '',
            partidoId : 0,
            votos: 0
        }
    ]
};

const votoSlice = createSlice({
    name: "pvoto",
    initialState,
    reducers: {
        addCVoto: (state,action) => {
            const {tipoVotoPos,tipoVotoId,descripcion,candidatoid,nombreCompleto,nombreMunicipio,clavePartido,partidoId,voto} = action.payload
            state.votoGeneral.push({
                'tipoVotoPos':tipoVotoPos,
                'tipoVotoId':tipoVotoId,
                'descripcion':descripcion,
                'candidatoid':candidatoid,
                'nombreCompleto':nombreCompleto,
                'nombreMunicipio':nombreMunicipio,
                'clavePartido':clavePartido,
                'partidoId' : partidoId,
                'voto' : voto                
            });
            
        }
    }
});
export const { addCVoto } = votoSlice.actions;
export default votoSlice.reducer;
